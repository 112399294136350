import { faComments, faEnvelope, faPhone, faVideoCamera } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Copy } from "."
import { Church } from "../../../utils/fetchChurches"
import sendMetric from "../../../utils/sendMetric"
import styles from "./Contact.module.css"

interface Props {
  church: Church
  copyHandler: (type: string, text: string) => void
  copy: Copy
}

const Contact = (props: Props): JSX.Element => {

  const copyEmail_onClick = () => {
    if (!props.church.email) return
    props.copyHandler('email', props.church.email)
  }
  
  const copyPhone_onClick = () => {
    if (!props.church.phone_number) return
    props.copyHandler('phone', props.church.phone_number)
  }
  
  const copyVideoPhone_onClick = () => {
    if (!props.church.video_phone_number) return
    props.copyHandler('videoPhone', props.church.video_phone_number)
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.churchIconWrapper}>
          <FontAwesomeIcon icon={faComments} />
        </div>
        <div className={styles.title}>Contact</div>
      </div>
      <div className={styles.wrapper}>
        {
          props.church.email &&
          <div className={styles.iconWrapper}>
            <div onClick={copyEmail_onClick}>
              <FontAwesomeIcon icon={faEnvelope} />
              <span className={styles.tooltipText}>{props.copy.email ? 'copied' : 'copy email address'}</span>
            </div>
            <div 
              onClick={() => {
                window.location.href = `mailto:${props.church.email}`
              }}
            >{props.church.email}</div>
          </div>
        }
        {
          props.church.phone_number &&
          <div className={styles.iconWrapper}>
            <div onClick={copyPhone_onClick}>
              <FontAwesomeIcon icon={faPhone} />
              <span className={styles.tooltipText}>{props.copy.phone ? 'copied' : 'copy phone number'}</span>
            </div>
            <div
              onClick={() => {
                sendMetric(props.church.id, 'clicked.startcall').then(function () {})
                window.location.href = `tel:${props.church.phone_number}`
              }}
            >{props.church.phone_number}</div>
          </div>
        }
        {
          props.church.video_phone_number &&
          <div className={styles.iconWrapper}>
            <div onClick={copyVideoPhone_onClick}>
              <FontAwesomeIcon icon={faVideoCamera} />
              <span className={styles.tooltipText}>{props.copy.videoPhone ? 'copied' : 'copy video phone number'}</span>
            </div>
            <div
              onClick={() => {
                sendMetric(props.church.id, 'clicked.startcall').then(function () {})
                window.location.href = `tel:${props.church.video_phone_number}`
              }}
            >{props.church.video_phone_number}</div>
          </div>
        }
      </div>
    </div>
  )
}

export default Contact