import axios from 'axios';

export interface Service {
  church_id: string
  day_of_week: string | null
  frequency: string | null
  id: string
  is_daycare_provided: string | null
  is_interpreting_provided: string | null
  is_voice_interpreting_provided: string | null
  live_service_details: string | null
  live_service_url: string | null
  ministry_type: string | null
  service_type: string | null
  start_time: string | null
  note: string | null
}

export interface Church {
  about_church: string | null
  about_church_video_url: string | null
  address: string | null
  city: string | null
  denomination: string | null
  email: string | null
  id: string
  latitude: string
  longitude: string
  members: string | null
  ministry_type: string | null
  name: string
  phone_number: string | null
  public_primary_picture: string | null
  services: Service[] | null
  social_facebook: string | null
  social_instagram: string | null
  social_twitter: string | null
  social_youtube_channel: string | null
  state: string | null
  video_phone_number: string | null
  website: string | null
  zip: string | null
}

const url = process.env.REACT_APP_METADATA_URI || '';

const fetchChurches = async (): Promise<Church[]> => {

  try {

    const response =  await axios.get<Church[]>(url)

    const churches: Church[] = response.data.map((church) => {
      return {
        about_church: church.about_church,
        about_church_video_url: church.about_church_video_url,
        address: church.address,
        city: church.city,
        denomination: church.denomination,
        email: church.email,
        id: church.id,
        latitude: church.latitude,
        longitude: church.longitude,
        members: church.members,
        ministry_type: church.ministry_type,
        name: church.name,
        phone_number: church.phone_number,
        public_primary_picture: church.public_primary_picture,
        services: church.services?.map((service) => {
          return {
            church_id: service.church_id,
            day_of_week: service.day_of_week,
            frequency: service.frequency,
            id: service.id,
            is_daycare_provided: service.is_daycare_provided,
            is_interpreting_provided: service.is_interpreting_provided,
            is_voice_interpreting_provided: service.is_voice_interpreting_provided,
            live_service_details: service.live_service_details,
            live_service_url: service.live_service_url,
            ministry_type: service.ministry_type,
            service_type: service.service_type,
            start_time: service.start_time,
            note: service.note
          }
        }) as Service[],
        social_facebook: church.social_facebook,
        social_instagram: church.social_instagram,
        social_twitter: church.social_twitter,
        social_youtube_channel: church.social_youtube_channel,
        state: church.state,
        video_phone_number: church.video_phone_number,
        website: church.website,
        zip: church.zip
      }
    }) as Church[]

    return churches

  } catch (error) {
    console.log(error)
    return []
  }
};

export default fetchChurches;