import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LngLat, selectedChurch } from "../../../redux/reducers"
import { churchDetail } from "../../../redux/selectors"
import { RootState } from "../../../redux/store"
import { Church } from "../../../utils/fetchChurches"
import styles from "./index.module.css"
import Picture from "./Picture"
import Info from "./Info"
import Links from "./Links"
import About from "./About"
import Contact from "./Contact"
import DeafMinistryType from "./DeafMinistryType"
import Services from "./Services"
import { useLocation, useNavigate } from "react-router-dom"

export interface MinistryType {
  type: string
  color: string
}

export interface Copy {
  address: boolean
  email: boolean
  phone: boolean
  videoPhone: boolean
}

interface Props {
  flyToChurchWithDetail: (name: string, lngLat: LngLat) => void
  isDragStart: boolean
  handlerCloseChurchDetail: Function
  handlerOpenChurchDetail: Function
  unclickMap: Function
}

const ChurchDetail = (props: Props): JSX.Element => {

  const location = useLocation()
  const navigate = useNavigate()
  const getPathName = location.pathname.split('/')

  const dispatch = useDispatch()

  const selected = useSelector((state: RootState) => state.map?.id)
  const selectedName = useSelector((state: RootState) => state.map?.church_name)
  const [church, setChurch] = useState<Church | null>(null)
  const getChurchDetail = useSelector(churchDetail(selected))[0]
  const [ministryType, setMinistryType] = useState<MinistryType>() 
  const [live, setLive] = useState<string | null>(null) 

  const [copy, setCopy] = useState<Copy>({
    address: false,
    email: false,
    phone: false,
    videoPhone: false
  })

  useEffect(() => {

    if (selected.length > 0 && getChurchDetail) {
      setChurch(getChurchDetail)
      navigate(`/church/${setupUrlSlug(selectedName)}/${getChurchDetail.id}`)
      props.handlerOpenChurchDetail()
      props.unclickMap()
    } else {

      setChurch(null)

      if (getPathName.length === 2 && /\b^add$\b/g.test(getPathName[1])) {
        navigate('/add')
      } 

    }
  }, [selected, getChurchDetail]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.isDragStart) {
      navigate('/')
      setChurch(null)
    }
  }, [props.isDragStart]) // eslint-disable-line react-hooks/exhaustive-deps

  function setupUrlSlug(churchName: string) {

    const addDash = churchName.replace(/\s/g , "-");
    const removeParentheses = addDash.replace(/[{()}]/g, '');
    const removeCommon = removeParentheses.replace(/,/g, '');
    const removePeriod = removeCommon.replace(/\./g,'');
    const removeQuotes = removePeriod.replace(/['"]+/g, '');
    const replaceSpecailCharacter_at = removeQuotes.replace(/[@]/g,'at');
    const replaceSpecailCharacter_and = replaceSpecailCharacter_at.replace(/[&]/g,'and');
    const onlyOneDash = replaceSpecailCharacter_and.replace(/-+/g, '-');
  
    return onlyOneDash.toLowerCase();
  }

  useEffect(() => {
    if (church && church.ministry_type) {

      const mt =  church.ministry_type!.toLowerCase().includes('supported') ||
                  church.ministry_type!.toLowerCase().includes('independent') ||
                  church.ministry_type!.toLowerCase().includes('deaf') ?
                    {type: 'deaf', color: '#f0b323'} : 
                    {type: 'interpreter', color: '#cccccc'}

      setMinistryType(mt)

      const l = church.services?.filter((service) => {
        return service.live_service_url ?
        service.live_service_url :
        null 
      })

      if (l) {
        l!.length > 0 ? setLive(l![0].live_service_url) : setLive(null)
      }

      resetCopy()

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [church])
  
  const exit_onClick = () => {
    dispatch(selectedChurch({name: '', lngLat: [0,0], id: ''}))
    navigate('/')
    props.handlerCloseChurchDetail()
  }

  const copyProcess = async (type: string, copiedText: string) => {

    let text = ''

    if (type.includes('address')) {
      text = copiedText.replaceAll(',,', ',')
    }

    navigator.clipboard.writeText(text)
      .then(() => {
        setCopy({
          address: type.includes('address') ? true : false,
          email: type.includes('email') ? true : false,
          phone: type.includes('phone') ? true : false,
          videoPhone: type.includes('videoPhone') ? true : false
        })
      })
  }

  useEffect(() => {
    document.body.addEventListener('copy', () => {
      resetCopy()
    })

    return () => {
      document.body.removeEventListener('copy', () => {
        resetCopy()
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function resetCopy() {
    setCopy({
      address: false,
      email: false,
      phone: false,
      videoPhone: false
    })
  }

  return (
    <>
      {
        selected.length > 0 &&
        church &&
        <div className={styles.container}>
          <div className={`${styles.exitIconWrapper} ${church.public_primary_picture ? '' : styles.bgColor}`} onClick={exit_onClick}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </div>
          <div>
            {
              church.public_primary_picture &&
              <Picture 
                url={church.public_primary_picture}
                name={church.name}
              />
            }
            <Info 
              church={church}
              live={live}
              ministryType={ministryType}
              copy={copy}
              copyHandler={copyProcess}
              flyToChurchWithoutDetail={props.flyToChurchWithDetail}
              closeDetail={exit_onClick}
            />
            <Links church={church} />
            {
              church.about_church &&
              <About 
                about={church.about_church!}
              />
            }
            {
              (church.email ||
              church.phone_number ||
              church.video_phone_number) &&
              <Contact 
                church={church}
                copy={copy}
                copyHandler={copyProcess}
              />
            }
            <DeafMinistryType 
              type={church.ministry_type!}
            />
            {
              church.services &&
              church.services.length > 0 &&
              <Services 
                services={church.services}
              />
            }
          </div>
        </div>
      }
    </>
  )
}

export default ChurchDetail