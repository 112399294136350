const sendMetric = (id: string, event: string): Promise<string | undefined> => {

  const metric_url = `${process.env.REACT_APP_METRIC}`

  return new Promise((resolve) => {

    const params = new URLSearchParams({
      app: 'deafchurchwhere',
      event: event,
      church_id: id
    }).toString()

    const url = metric_url+params

    const requestOptions: any = {
      mode: 'no-cors',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: {}
    }

    fetch(url, requestOptions)
      .then(async response => {
        return resolve('ok')
      })
      .catch(error => {
        return resolve(undefined)
      })

  })

}

export default sendMetric