import { faFacebook, faInstagram, faYoutube, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faDirections, faGlobe } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Church } from "../../../utils/fetchChurches"
import direction from "../../../utils/direction"
import styles from "./Links.module.css"
import sendMetric from "../../../utils/sendMetric"

interface Props {
  church: Church
}

const Links = (props: Props): JSX.Element => {

  const directions_onClick = () => {
    sendMetric(props.church.id, 'clicked.opendirections').then(function () {})
    const url = direction([parseFloat(props.church.longitude), parseFloat(props.church.latitude)])
    window.open(url, "_self");
  }

  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.topButtonsWrapper}>
        {
          props.church.website &&
          <div 
            className={`${styles.websiteIconWrapper} ${props.church.website ? styles.mgRt : ''}`} 
            onClick={() => {
              sendMetric(props.church.id, 'clicked.openwebsite').then(function () {})

              props.church.website!.includes('https://') ||
              props.church.website!.includes('http') ?
                window.open(props.church.website!, "_blank") :
                window.open('http://'+props.church.website!, "_blank")

            }}
          >
            <FontAwesomeIcon icon={faGlobe} />
            <div>Website</div>
          </div>
        }
        <div className={styles.directionIconWrapper} onClick={directions_onClick}>
          <FontAwesomeIcon icon={faDirections} />
          <div>Directions</div>
        </div>
      </div>
      {
        (props.church.social_facebook ||
        props.church.social_instagram ||
        props.church.social_youtube_channel ||
        props.church.social_twitter) &&
        <div className={styles.bottomButtonsWrapper}>
          {
            props.church.social_facebook &&
            <div 
              className={styles.brandIconWrapper}
              onClick={() => window.open(props.church.social_facebook!, "_blank")}
            >
              <FontAwesomeIcon icon={faFacebook} style={{color: '#4267B2'}} />
            </div>
          }
          {
            props.church.social_instagram &&
            <div 
              className={styles.brandIconWrapper}
              onClick={() => {
                sendMetric(props.church.id, 'clicked.openinstagram').then(function () {})
                window.open(props.church.social_instagram!, "_blank")}
              } 
            >
              <FontAwesomeIcon icon={faInstagram} style={{color: '#833AB4'}} />
            </div>
          }
          {
            props.church.social_youtube_channel &&
            <div 
              className={styles.brandIconWrapper}
              onClick={() => {
                sendMetric(props.church.id, 'clicked.openyoutube').then(function () {})
                window.open(props.church.social_youtube_channel!, "_blank")}
              } 
            >
              <FontAwesomeIcon icon={faYoutube} style={{color: '#FF0000'}} />
            </div>
          }
          {
            props.church.social_twitter &&
            <div 
              className={styles.brandIconWrapper}
              onClick={() => window.open(props.church.social_youtube_channel!, "_blank")}
            >
              <FontAwesomeIcon icon={faTwitter} style={{color: '#1DA1F2'}}/>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default Links