import { faBookBible } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./DeafMinistryType.module.css"

interface Props {
  type: string
}

const DeafMinistryType = (props: Props): JSX.Element => {

  function output(): string {

    if (!props.type) return "No information is available on type of services provided."

    switch(props.type.toLowerCase()) {
      case 'accessibility':
        return 'Some services are interpreted.'
      case 'integrated':
        return 'Some bible study / sunday school services are led by Deaf people. Worship services are interpreted.'
      case 'supported': 
      case 'independent':
        return 'All services are fully signed and led by Deaf people.'
      default:
        return 'No information is available on type of services provided.'
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.churchIconWrapper}>
          <FontAwesomeIcon icon={faBookBible} />
        </div>
        <div className={styles.title}>Deaf Ministry Type</div>
      </div>
      <div>{output()}</div>
    </div>
  )
}

export default DeafMinistryType