import { LngLat } from "../../../redux/reducers"
import styles from "./index.module.css"

interface Props {
  placeName: string
  lngLat: LngLat
  flyTo: (lngLat: LngLat) => void
  closePopup: () => void
  hideSuggestions: () => void
  resetDisplayLevel: () => void
}

const Suggestion = (props: Props): JSX.Element => {

  const place_onClick = () => {
    props.hideSuggestions()
    props.flyTo(props.lngLat)
    props.closePopup()
    props.resetDisplayLevel()
  }

  return (
    <div 
      className={styles.rowWrapper} 
      onClick={place_onClick}
    >
      <div>{props.placeName}</div>
    </div>
  )
}

export default Suggestion