import { faChurch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./About.module.css"

interface Props {
  about: string
}

const About = (props: Props): JSX.Element => {

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.churchIconWrapper}>
          <FontAwesomeIcon icon={faChurch} />
        </div>
        <div className={styles.title}>About</div>
      </div>
      <div>{props.about}</div>
    </div>
  )
}

export default About