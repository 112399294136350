import styles from './index.module.css'

interface Props {
  selectedDeaf: () => void
  selectedHearing: () => void
  selected: boolean
  unclickMap: Function
}

const ToggleMarkers = (props: Props): JSX.Element => {

  const handleDeaf_onClick = () => {
    props.selectedDeaf()
    props.unclickMap()
  }
  
  const handleHearing_onClick = () => {
    props.selectedHearing()
    props.unclickMap()
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={`${styles.btnWrapper} ${!props.selected ? styles.unselected : ''}`} onClick={handleDeaf_onClick}>
          <div>Deaf</div>
        </div>
        <div className={`${styles.btnWrapper} ${props.selected ? styles.unselected : ''}`} onClick={handleHearing_onClick}>
          <div>Interpreter</div>
        </div>
      </div>
    </div>
  )
}

export default ToggleMarkers