import { LngLat } from "../../../redux/reducers"
import Suggestion from "../Suggestion"

interface Props {
  found: Array<any>
  flyTo: (lngLat: LngLat) => void
  closePopup: () => void
  hideSuggestions: () => void
  resetDisplayLevel: () => void
}

const Suggestions = (props: Props): JSX.Element => {
  return (
    <div>
      {
        props.found.length > 0 &&
        props.found.map((place) => {
          return (
            <Suggestion 
              key={place.id}
              placeName={place.place_name}
              lngLat={place.center}
              flyTo={props.flyTo}
              closePopup={props.closePopup}
              hideSuggestions={props.hideSuggestions}
              resetDisplayLevel={props.resetDisplayLevel}
            />
          )
        })
      }
    </div>
  )
}

export default Suggestions