import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { 
  Feature, 
  FeatureCollection, 
  Geometry, 
  GeoJsonProperties 
} from "geojson";
import { Church } from "../utils/fetchChurches";
import distance from "../utils/distance";
import { SimpleChurch } from "../components/Map";

const RANGE = 150

export interface Source {
  type: string
  data: {
    type: FeatureCollection<Geometry, GeoJsonProperties> | string
    features: Feature[]
  }
}

const churches = (state: RootState) => state.map.all_churches
const userLocation = (state: RootState) => state.map.user_lngLat

export const churchDetail = (id: string) => createSelector(
  churches,
  (churches): Church[] => {
    return churches.filter((church) => {
      return church.id === id
    })
  }
)

export const processDeafSource = createSelector(
  churches,
  (churches): Source => {
    
    var generateSourceObj: Source = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [{
          type: 'Feature',
          properties: {
            id: '',
            name: '',
            denomination: ''
          },
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        }]
      }
    }

    for (var i = 0; i < churches.length; i++) {

      if (
        churches[i].ministry_type !== null && 
        (churches[i].ministry_type!.toLowerCase().includes('supported') ||
         churches[i].ministry_type!.toLowerCase().includes('independent') ||
         churches[i].ministry_type!.toLowerCase().includes('deaf'))
       ) {

        generateSourceObj.data.features!.push({
          type: "Feature",
          properties: {
            id: churches[i].id,
            name: churches[i].name,
            denomination: churches[i].denomination
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(churches[i].longitude), 
              parseFloat(churches[i].latitude)
            ]
          }
        })

      } // end if
    } // end loop

    generateSourceObj.data.features.shift()

    return generateSourceObj
  }
)

export const processHearingSource = createSelector(
  churches,
  (churches): Source => {

    var generateSourceObj: Source = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [{
          type: 'Feature',
          properties: {
            id: '',
            name: '',
            denomination: ''
          },
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        }]
      }
    }

    for (var i = 0; i < churches.length; i++) {

      if (churches[i].ministry_type !== null &&
          (churches[i].ministry_type!.toLowerCase().includes('accessibility') ||
          churches[i].ministry_type!.toLowerCase().includes('integrated') ||
          churches[i].ministry_type!.toLowerCase().includes('interpreted'))
       ) {

        generateSourceObj.data.features!.push({
          type: "Feature",
          properties: {
            id: churches[i].id,
            name: churches[i].name,
            denomination: churches[i].denomination,
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(churches[i].longitude), 
              parseFloat(churches[i].latitude)
            ]
          }
        })

      } // end if
    } // end loop

    generateSourceObj.data.features.shift()

    return generateSourceObj
  }
)

export const getNearDeafChurches = createSelector(
  churches,
  userLocation,
  (churches, userLocation): SimpleChurch[] => {

    const deaf: SimpleChurch[] = [] 
    
    churches.filter((church) => {

      const lng = parseFloat(church.longitude)
      const lat = parseFloat(church.latitude)

      if (church.ministry_type !== null &&
          (church.ministry_type!.toLowerCase().includes('supported') ||
          church.ministry_type!.toLowerCase().includes('independent') ||
          church.ministry_type!.toLowerCase().includes('deaf'))
      ) {
        const isWithInRange =  distance([lng, lat], userLocation) <= RANGE 
        if (isWithInRange) {
          deaf.push({
            id: church.id,
            name: church.name,
            denomination: church.denomination ? church.denomination : null,
            longitude: parseFloat(church.longitude),
            latitude: parseFloat(church.latitude),
          })
        }
      }

      return undefined
      
    }) // end filter

    return deaf.sort((a, b) => (
        distance([a.longitude, a.latitude], userLocation) - distance([b.longitude, b.latitude], userLocation)
      ))
  }
)

export const getNearHearingChurches = createSelector(
  churches,
  userLocation,
  function (churches, userLocation): SimpleChurch[] {

    const hearing: SimpleChurch[] = []
    
    churches.filter((church) => {

      const lng = parseFloat(church.longitude);
      const lat = parseFloat(church.latitude);

      if (church.ministry_type !== null &&
          (church.ministry_type!.toLowerCase().includes('accessibility') ||
           church.ministry_type!.toLowerCase().includes('integrated') ||
           church.ministry_type!.toLowerCase().includes('interpreted'))
       ) {
        const isWithInRange = distance([lng, lat], userLocation) <= RANGE;
        if (isWithInRange) {
          hearing.push({
            id: church.id,
            name: church.name,
            denomination: church.denomination ? church.denomination : null,
            longitude: parseFloat(church.longitude),
            latitude: parseFloat(church.latitude),
          })
        }
      }

      return undefined;

    }); // end filter

    return hearing.sort((a, b) => (
      distance([a.longitude, a.latitude], userLocation) - distance([b.longitude, b.latitude], userLocation)
    ));
  }
)
