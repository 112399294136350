import Map from "../Map"

const App = (): JSX.Element => {
  return (
    <div>
      <Map />
    </div>
  );
}

export default App;
