import { useState } from "react"
import { LngLat } from "../../redux/reducers"
import AddChurchIcon from "./AddChurchIcon"
import ChurchDetail from "./Church"
import Search from "./Search"
import SearchThisArea from "./SearchThisArea"
import ToggleMarkers from "./ToggleMarkers"
import UserLocationIcon from "./UserLocationIcon"
import '../../fonts/Roboto-Black.ttf'

interface Props {
  flyTo: (lngLat: LngLat) => void
  flyToChurch: (name: string, lngLat: LngLat, id: string) => void
  flyToChurchWithoutDetail: (name: string, lngLat: LngLat) => void
  closePopup: () => void
  markersWithinRange: any[]
  isDragStart: boolean
  hoverPopup: (enter: string | null, lngLat: LngLat) => void
  selectedDeaf: () => void
  selectedHearing: () => void
  selectedMarker: boolean
  isMapReady: boolean
  areaDeafChurches: any[]
  areaHearingChurches: any[]
  shouldDisplayArea: boolean
  updateQueryRenderedChurches: Function
  loading: boolean
  unclickMap: Function
  clickedMap: boolean
}

const UI = (props: Props): JSX.Element => {

  const [isAddChurchClicked, setIsAddChurchClicked] = useState<boolean>(false)
  const [isDisplayAreaClicked, setIsDisplayAreaClicked] = useState<boolean>(false)
  const [isCloseChurchDetail, setIsCloseChurchDetail] = useState<boolean>(false)
  const [isCloseAddChurch, setIsCloseAddChurch] = useState<boolean>(false)
  const [clickArea, setClickArea] = useState<boolean>(false)
  const [clickUserLocation, setClickUserLocation] = useState<boolean>(false)
  const [clickExitChurchForm, setExitChurchForm] = useState<boolean>(false)

  function displayAddChurchForm() {
    setIsAddChurchClicked(true)
    props.closePopup()
  }
  
  function hideAddChurchForm() {
    setIsAddChurchClicked(false)
    props.closePopup()
  }
  
  function displayAreaClick() {
    setIsDisplayAreaClicked(true)
    props.closePopup()
  }
  
  function closeChurchDetailClick() {
    setIsCloseChurchDetail(true)
    props.closePopup()
  }
  
  function openChurchDetailClick() {
    setIsCloseChurchDetail(false)
  }
  
  function closeAddChurch() {
    setIsCloseAddChurch(true)
    props.closePopup()
  }
  
  function openAddChurch() {
    setIsCloseAddChurch(false)
    props.closePopup()
  }

  function handlerClickArea() {
    clickArea ? 
      setClickArea(false) :
      setClickArea(true)
  }
  
  function handlerClickUserLocation() {
    clickUserLocation ?
      setClickUserLocation(false) :
      setClickUserLocation(true)
  }

  function handlerExitChurchForm() {
    clickExitChurchForm ?
      setExitChurchForm(false) :
      setExitChurchForm(true)
  }

  return (
    <>
      <Search 
        flyTo={props.flyTo}
        flyToChurch={props.flyToChurch}
        flyToChurchWithoutDetail={props.flyToChurchWithoutDetail}
        closePopup={props.closePopup}
        markersWithinRange={props.markersWithinRange}
        isDragStart={props.isDragStart}
        hoverPopup={props.hoverPopup}
        isMapReady={props.isMapReady}
        deafMarkersWithinArea={props.areaDeafChurches}
        hearingMarkersWithinArea={props.areaHearingChurches}
        loading={props.loading}
        isAddChurchClicked={isAddChurchClicked}
        isDisplayAreaClicked={isDisplayAreaClicked}
        markerSource={props.selectedMarker}
        closeChurchDetail={isCloseChurchDetail}
        hideChurchform={hideAddChurchForm}
        isCloseAddChurch={isCloseAddChurch}
        handlerOpenAddChurch={openAddChurch}
        handlerCloseAddChurch={closeAddChurch}
        clickedArea={clickArea}
        clickedUserLocation={clickUserLocation}
        clickedExitChurchForm={clickExitChurchForm}
        unclickMap={props.unclickMap}
        clickedMap={props.clickedMap}
      />
      <ToggleMarkers 
        selectedDeaf={props.selectedDeaf}
        selectedHearing={props.selectedHearing}
        selected={props.selectedMarker}
        unclickMap={props.unclickMap}
      />
      <ChurchDetail 
        flyToChurchWithDetail={props.flyToChurchWithoutDetail}
        isDragStart={props.isDragStart}
        handlerCloseChurchDetail={closeChurchDetailClick}
        handlerOpenChurchDetail={openChurchDetailClick}
        unclickMap={props.unclickMap}
      />
      <AddChurchIcon 
        displayAddChurchForm={displayAddChurchForm}
        hideAddChurchForm={hideAddChurchForm}
        handlerCloseAddChurch={closeAddChurch}
        handlerOpenAddChurch={openAddChurch}
        clickedExitAddChurch={handlerExitChurchForm}
        unclickMap={props.unclickMap}
        clickMap={props.clickedMap}
      />
      {
        props.shouldDisplayArea &&
        <SearchThisArea 
          updateQueryRenderedChurches={props.updateQueryRenderedChurches}
          displayAreaClicked={displayAreaClick}
          handlerClickArea={handlerClickArea}
          unclickMap={props.unclickMap}
        />
      }
      <UserLocationIcon
        flyTo={props.flyTo}
        clickedUserLocation={handlerClickUserLocation}
        unclickMap={props.unclickMap}
      />
    </>
  )
}

export default UI