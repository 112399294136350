import { LngLat } from '../redux/reducers';
import { isiOS } from './userAgent';

const googleMaps = 'https://www.google.com/maps/dir/?api=1&destination=';
const appleMaps = 'http://maps.apple.com/?daddr=';

const direction = (lngLat: LngLat): string => {
  const iOS = isiOS();
  const uri = encodeURI(`${iOS ? appleMaps : googleMaps}${lngLat[1]},${lngLat[0]}`);
  return uri;
};

export default direction;