import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import { fetchAllChurches, fetchUserLocation } from './redux/reducers';
import App from './components/App/App';
import './index.module.css';
import { BrowserRouter } from 'react-router-dom';

store.dispatch(fetchAllChurches())
store.dispatch(fetchUserLocation())

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
