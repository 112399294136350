import { 
  configureStore, 
  combineReducers 
} from '@reduxjs/toolkit';

import map from "./reducers"

const reducer = combineReducers({
  map
})

const store = configureStore({ reducer });

export type RootState = ReturnType<typeof reducer>

export default store;