import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import styles from "./Form.module.css"

interface Props {
  closeForm: () => void
  clickMap: boolean
}

const Form = (props: Props): JSX.Element => {

  const location = useLocation()
  const getPathName = location.pathname.split('/')

  const [churchName, setChurchName] = useState<string>('')
  const [churchAddress, setChurchAddress] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const [isDisplay, setIsDisplay] = useState<boolean>(false)

  useEffect(() => {
    if (getPathName.length === 2 && /\b^add$\b/g.test(getPathName[1])) {
      setIsDisplay(true)
    } else {
      setIsDisplay(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (props.clickMap) {
      setIsDisplay(false)
    }
  }, [props.clickMap])

  function handler_onSubmit(e: FormEvent<HTMLFormElement>) {
    
    if (churchName.length === 0) {
      e.preventDefault()
      alert("Please fill out the church name.")
      return false
    }

    if (churchAddress.length === 0) {
      e.preventDefault()
      alert("Please fill out the church address.")
      return false
    }

    if (email.length === 0) {
      e.preventDefault()
      alert("Please fill out your email address.")
      return false
    }
    
    if (!isEmailValid(email)) {
      e.preventDefault()
      alert("Email Format Invalid - Please fill out the correct email address");
      return false
  }

    return true
  }

  function isEmailValid(str: string) {
    var re =  /\S+@\S+\.\S+/;
    return re.test(str);
  }

  const handler_churchName_onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChurchName(e.target.value)
  }

  const handler_churcAddress_onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setChurchAddress(e.target.value)
  }

  const handler_email_onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  
  function reset() {
    setChurchName('')
    setChurchAddress('')
    setEmail('')
  }

  return (
    <div className={`${styles.formContainer} ${isDisplay ? styles.displayFormContainer : ''}`}>
      <div>
        <div className={styles.formWrapper}>
          <div className={styles.exitIconWrapper} onClick={()=> {
              props.closeForm()
              reset()
            }
          }>
            <FontAwesomeIcon icon={faCircleXmark} />
          </div>
          <div className={styles.title}>Add Church</div>
          <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" onSubmit={handler_onSubmit}>
            <input type="hidden" name="oid" value="00D3i000000Ehjo" />
            <input type="hidden" name="retURL" value="https://app.deafchurchwhere.com/" />
            <div>
              <div className={styles.inputWrapper}>
                <div>Church Name</div>
                <input 
                  id="00N3i00000CkrKF" 
                  maxLength={255} 
                  name="00N3i00000CkrKF" 
                  size={20} 
                  type="text"
                  onChange={handler_churchName_onChange}
                  value={churchName}
                />
              </div>
              <div className={styles.inputWrapper}>
                <div>Address</div>
                <textarea  
                  id="00N3i00000CnURI" 
                  name="00N3i00000CnURI" 
                  typeof="text" 
                  wrap="soft"
                  onChange={handler_churcAddress_onChange}
                  value={churchAddress}
                ></textarea>
              </div>
              <div className={styles.inputWrapper}>
                <div>Email</div>
                <input  
                  id="00N3i00000CkrKK" maxLength={80} 
                  name="00N3i00000CkrKK" 
                  size={20} 
                  type="text" 
                  onChange={handler_email_onChange}
                  value={email}
                />
              </div>
              <div>
                <div className={styles.radioGroup}>
                  <div className={styles.inputWrapper}>
                    <div>Deaf</div>
                    <input 
                      id="00N3i00000DPc7F" 
                      name="00N3i00000DPc7F" 
                      type="radio" 
                      value={"Deaf"} 
                      defaultChecked
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <div>Interpreter</div>
                    <input 
                      id="00N3i00000DPc7F" 
                      name="00N3i00000DPc7F" 
                      type="radio" 
                      value={"Interpreter"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <select hidden id="lead_source" name="lead_source" defaultValue={"Web"}>
              <option value="">--None--</option>
              <option value="Web">Web</option>
              <option value="Deaf Church Where">Deaf Church Where</option>
              <option value="Phone Inquiry">Phone Inquiry</option>
              <option value="Partner Referral">Partner Referral</option>
              <option value="Other">Other</option>
              <option value="2022 Deaf Nation World Expo">2022 Deaf Nation World Expo</option>
            </select>
            <div className={styles.buttonWrapper}>
              <input type="submit" name="submit" value={"Add"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form
