import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { reset } from "../../../redux/reducers"
import Form from "./Form"

import styles from "./index.module.css"

interface Props {
  displayAddChurchForm: Function
  hideAddChurchForm: Function
  handlerCloseAddChurch: Function
  handlerOpenAddChurch: Function
  clickedExitAddChurch: Function
  unclickMap: Function
  clickMap: boolean
}

const AddChurchIcon = (props: Props): JSX.Element => {

  const location = useLocation()
  const navigate = useNavigate()
  const getPathName = location.pathname.split('/')
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (getPathName.length === 2 && /\b^add$\b/g.test(getPathName[1])) {
      props.unclickMap()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handle_plusIcon_onClick = () => {
    navigate('/add')
    dispatch(reset())
    props.displayAddChurchForm()
    props.handlerOpenAddChurch()
    props.unclickMap()
  }

  const closeForm = () => {
    navigate('/')
    props.handlerCloseAddChurch()
    props.clickedExitAddChurch()
    props.unclickMap()
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.plusIconWrapper} onClick={handle_plusIcon_onClick }>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
      <Form
        closeForm={closeForm}
        clickMap={props.clickMap}
      />
    </div>
  )
}

export default AddChurchIcon