
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDispatch, useSelector } from "react-redux"
import { LngLat, reset, setSearchType } from "../../../redux/reducers"
import { RootState } from "../../../redux/store"
import styles from "./index.module.css"

interface Props {
  flyTo: (lngLat: LngLat) => void
  clickedUserLocation: Function
  unclickMap: Function
}

const UserLocationIcon = (props: Props): JSX.Element => {

  const dispatch = useDispatch()
  const { 
    searchType,
    user_lngLat
  } = useSelector((state: RootState) => state.map)

  const handler_userLocation_onClick = () => {
    props.clickedUserLocation()
    props.flyTo(user_lngLat)
    props.unclickMap()
    dispatch(reset())
    if (searchType === 0) return
    dispatch(setSearchType(true))
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.userLocationIconWrapper} onClick={handler_userLocation_onClick}>
          <FontAwesomeIcon icon={faLocationCrosshairs} />
        </div>
      </div>
    </div>
  )
}

export default UserLocationIcon