// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { 
  Feature, 
  FeatureCollection, 
  Geometry, 
  GeoJsonProperties 
} from "geojson";
import { RootState } from '../../redux/store'
import { 
  LngLat,
  reset,
  selectedChurch, 
  setCurrentLongLat, 
  setCurrentZoom,
} from '../../redux/reducers'
import { 
  getNearDeafChurches, 
  getNearHearingChurches, 
  processDeafSource, 
  processHearingSource 
} from '../../redux/selectors'
import UI from '../UI'
import styles from './index.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import distance from '../../utils/distance';
import sendMetric from '../../utils/sendMetric';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string

interface Source {
  type: string
  data: {
    type: FeatureCollection<Geometry, GeoJsonProperties> | string
    features: Feature[]
  }
}

export interface SimpleChurch {
  id: string
  name: string
  denomination: string | null
  longitude: number
  latitude: number
}

const Map = (): JSX.Element => {
  
  const dispatch = useDispatch()
  
  const location = useLocation()
  const navigate = useNavigate()
  const pathname = location.pathname.slice(1).split('/')

  const { 
    current_lngLat, 
    current_zoom,
    church_name,
    user_lngLat,
    searchType,
    all_churches
  } = useSelector((state: RootState) => state.map)

  let deafSource: Source = useSelector(processDeafSource)
  let hearingSource: Source = useSelector(processHearingSource)
  
  let nearDeafChurches = useSelector(getNearDeafChurches)
  let nearHearingChurches = useSelector(getNearHearingChurches)
  
  let [areaDeafChurches, setAreaDeafChurches] = useState<SimpleChurch[]>([]) 
  let [areaHearingChurches, setAreaHearingChurches] = useState<SimpleChurch[]>([])
  
  const mapContainerRef = useRef<any>(null)
  const map = useRef<any>(null)
  const popup = useRef<any>(null)
  const selected_popup = useRef<any>(null)

  const [lng, setLng] = useState(current_lngLat[0])
  const [lat, setLat] = useState(current_lngLat[1])
  const [zoom, setZoom] = useState(current_zoom)

  const [markerWithinRange, setMarkerWithInRange] = useState<SimpleChurch[]>(nearDeafChurches)
  const [markersSource, setMarkersSource] = useState<boolean>(true)
  const [isDragStart, setIsDragStart] = useState<boolean>(false)
  const [isMapReady, setIsMapReady] = useState<boolean>(false)
  const [shouldDisplayArea, setShouldDisplayArea] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false)

  const [clickMap, setClickMap] = useState<boolean>(false)

  useEffect(() => {

    if (pathname.length > 2) {
      if (pathname[2].length > 0) {
        if (all_churches.length > 0) {

          const church = all_churches.find((church) => church.id === pathname[2])

          if (church !== undefined) {

            dispatch(selectedChurch({
              name: church.name,
              lngLat: [parseFloat(church.longitude), parseFloat(church.latitude)],
              id: church.id
            }))
            
            if (church.ministry_type) {

              if (!(church.ministry_type!.toLowerCase().includes('supported') ||
                    church.ministry_type!.toLowerCase().includes('independent') || 
                    church.ministry_type!.toLowerCase().includes('deaf'))) 
              {
                setMarkersSource(false)
                map.current.on('load', () => {
                  map.current.setLayoutProperty('deafChurches', 'visibility', 'none')
                  map.current.setLayoutProperty('hearingChurches', 'visibility', 'visible')
                })
                
              } 
            }

            
            flyToChurch(church.name, [parseFloat(church.longitude), parseFloat(church.latitude)], church.id)
          
          } else {
            dispatch(reset())
            navigate('/')
          }

        }
      }
    }

  }, [all_churches]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (isFirstTime) {
      setLoading(true)
      setTimeout(() => {
        updateQueryRenderedChurches()
        if (searchType === 0) {
          markersSource ? setMarkerWithInRange(nearDeafChurches) : setMarkerWithInRange(nearHearingChurches)
        }
        setLoading(false) 
      }, 2000)
    }

    setIsFirstTime(true)

  }, [searchType, markersSource]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMarkerWithInRange(nearDeafChurches)
  }, [nearDeafChurches])

  // initialize map when component mounts
  useEffect(() => {

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    })

    popup.current = new mapboxgl.Popup({ 
      closeButton: false,
      offset: [0, -14],
      anchor: 'bottom'
    })

    selected_popup.current = new mapboxgl.Popup({ 
      closeButton: false,
      closeOnClick: false,
      offset: [0, -14],
      anchor: 'bottom'
    })

    map.current.on('load', async () => {

      setIsMapReady(true)

      // deaf churches data
      map.current.addSource("deafChurches", { 
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: deafSource.data.features
        }
      }) // end map addSource

      map.current.addLayer({
        id: 'deafChurches',
        type: "circle",
        source: "deafChurches",
        paint: {
          "circle-color": "#f0b323",
          "circle-radius": 10,
          "circle-stroke-color": "#333333",
          "circle-stroke-width": 2,
        },
      }) // end map addLayer

      // hearing churches data
      map.current.addSource("hearingChurches", { 
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: hearingSource.data.features
        }
      }) // end map addSource

      map.current.addLayer({
        id: 'hearingChurches',
        type: "circle",
        source: "hearingChurches",
        paint: {
          "circle-color": "#cccccc",
          "circle-radius": 10,
          "circle-stroke-color": "#333333",
          "circle-stroke-width": 2,
        },
      }) // end map addLayer

      markersEventSetup()

      map.current.flyTo({
        center: [lng, lat],
        zoom: zoom,
        essential: false,
        duration: 0
      })

      map.current.on('move', () => {
        const { lng, lat } = map.current.getCenter()
        const z = map.current.getZoom()
        setLng(lng)
        setLat(lat)
        setZoom(z)
      }) // end map move
      
      map.current.on('dragend', () => {
        const { lng, lat } = map.current.getCenter()
        dispatch(setCurrentLongLat([lng, lat]))
        setIsDragStart(false)
        popup.current.remove()
      }) // end map dragend
      
      map.current.on('zoomend', () => {
        const z = map.current.getZoom()
        dispatch(setCurrentZoom(z))
      }) // end map zoomend

      map.current.on('dragstart', () => {
        setIsDragStart(true)
        dispatch(reset())
        closePopup()
      }) // end map dragstart

      map.current.on('click', () => {
        setClickMap(true)
      })

    }) // end map load

    map.current.on('style.load', () => {
      map.current.getSource('deafChurches').setData({
        "type": "FeatureCollection",
        "features": deafSource.data.features
      })

      map.current.getSource('hearingChurches').setData({
        "type": "FeatureCollection",
        "features": hearingSource.data.features
      })
    })

    // Clean up on unmount
    return () => {
      map.current.remove()
      popup.current.remove()
      selected_popup.current.remove()
    }
    
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function unclickMap() {
    setClickMap(false)
  }

  useEffect(() => {
    distance([lng, lat], user_lngLat) > 49 ? 
      setShouldDisplayArea(true) :
      setShouldDisplayArea(false)
  }, [lng, lat]) // eslint-disable-line react-hooks/exhaustive-deps

  // reload markers
  useEffect(() => {
    map.current.on('load', async () => {

      setIsMapReady(true)

      map.current.flyTo({
        center: current_lngLat,
        zoom: current_zoom,
        essential: true,
        duration: 0
      })

      map.current.getSource('deafChurches').setData({
        "type": "FeatureCollection",
        "features": deafSource.data.features
      })

      map.current.getSource('hearingChurches').setData({
        "type": "FeatureCollection",
        "features": hearingSource.data.features
      })

      setLng(current_lngLat[0])
      setLat(current_lngLat[1])

    })
  })

  useEffect(() => {
    if (church_name.length === 0) {
      selected_popup.current.remove()
    } 
  }, [church_name])

  useEffect(() => {

    map.current.on('load', () => {
      if (markersSource) {
        map.current.setLayoutProperty('hearingChurches', 'visibility', 'none')
        map.current.setLayoutProperty('deafChurches', 'visibility', 'visible')
      } else {
        map.current.setLayoutProperty('deafChurches', 'visibility', 'none')
        map.current.setLayoutProperty('hearingChurches', 'visibility', 'visible')
      }
    })

    map.current.on('idle', () => {
      if (markersSource) {
        map.current.setLayoutProperty('hearingChurches', 'visibility', 'none')
        map.current.setLayoutProperty('deafChurches', 'visibility', 'visible')
      } else {
        map.current.setLayoutProperty('deafChurches', 'visibility', 'none')
        map.current.setLayoutProperty('hearingChurches', 'visibility', 'visible')
      }
    })

  }, [markersSource])

  function markersEventSetup() {

    map.current.on('click', 'deafChurches', (e: any) => {

      const coordinates = e.features[0].geometry.coordinates.slice()
      const churchName = e.features[0].properties.name
      const id = e.features[0].properties.id

      sendMetric(id, 'clicked.moreinfo').then(function () {})
      
      dispatch(selectedChurch({ 
        name: churchName, 
        lngLat: [coordinates[0], coordinates[1]],
        id: id 
      }))

      dispatch(setCurrentLongLat([coordinates[0], coordinates[1]]))

      setTimeout(() => {

        map.current.flyTo({
          center: coordinates,
          zoom: 11,
          essential: true,
          duration: 0
        })

        selected_popup.current
          .setLngLat(coordinates)
          .setHTML(`<div>${churchName}</div>`)
          .addTo(map.current)

      }, 1000)

        
    }) // end map churches click

    map.current.on('click', 'hearingChurches', (e: any) => {

      const coordinates = e.features[0].geometry.coordinates.slice()
      const churchName = e.features[0].properties.name
      const id = e.features[0].properties.id

      sendMetric(id, 'clicked.moreinfo').then(function () {})
        
      dispatch(selectedChurch({ 
        name: churchName, 
        lngLat: [coordinates[0], coordinates[1]],
        id: id 
      }))

      dispatch(setCurrentLongLat([coordinates[0], coordinates[1]]))

      setTimeout(() => {

        map.current.flyTo({
          center: coordinates,
          zoom: 11,
          essential: true,
          duration: 0
        })

        selected_popup.current
          .setLngLat(coordinates)
          .setHTML(`<div>${churchName}</div>`)
          .addTo(map.current)

      }, 1000)
        
    }) // end map churches click

    map.current.on('mouseenter', 'deafChurches', (e: any) => {
      map.current.getCanvas().style.cursor = 'pointer'

      const coordinates = e.features[0].geometry.coordinates.slice()
      const churchName = e.features[0].properties.name
      
      popup.current
        .setLngLat(coordinates)
        .setHTML(`<div>${churchName}</div>`)
        .addTo(map.current)

    }) // end map mouseenter
    
    map.current.on('mouseleave', 'deafChurches', (e: any) => {
      map.current.getCanvas().style.cursor = ''
      popup.current.remove()
    }) // end map mouseleave
    
    map.current.on('mouseenter', 'hearingChurches', (e: any) => {
      map.current.getCanvas().style.cursor = 'pointer'

      const coordinates = e.features[0].geometry.coordinates.slice()
      const churchName = e.features[0].properties.name
      
      popup.current
        .setLngLat(coordinates)
        .setHTML(`<div>${churchName}</div>`)
        .addTo(map.current)

    }) // end map mouseenter
    
    map.current.on('mouseleave', 'hearingChurches', (e: any) => {
      map.current.getCanvas().style.cursor = ''
      popup.current.remove()
    }) // end map mouseleave

  }

  function removePopup() {
    popup.current.remove()
  } 

  function updateQueryRenderedChurches() {

    if (markersSource) {
      
      const deaf: SimpleChurch[] = map.current.queryRenderedFeatures({layers: ['deafChurches']}).map((marker: any) => {
        return {
          id: marker.properties.id,
          name: marker.properties.name,
          denomination: marker.properties.denomination,
          longitude: marker.geometry.coordinates[0],
          latitude: marker.geometry.coordinates[1]
        }
      })

      setAreaDeafChurches(deaf)
      setMarkerWithInRange(deaf)
    
    } else {
      
      const hearing: SimpleChurch[] = map.current.queryRenderedFeatures({layers: ['hearingChurches']}).map((marker: any) => {
        return {
          id: marker.properties.id,
          name: marker.properties.name,
          denomination: marker.properties.denomination,
          longitude: marker.geometry.coordinates[0],
          latitude: marker.geometry.coordinates[1]
        }
      })
      setAreaHearingChurches(hearing)
      setMarkerWithInRange(hearing)
    
    } // end if
  } // end updateQueryRenderedChurches

  function flyTo(lngLat: LngLat) {
    map.current.flyTo({
      center: lngLat,
      zoom: 11,
      essential: true,
      duration: 0
    })
  }

  function flyToChurch(name: string, lngLat: LngLat, id: string) {
    
    dispatch(selectedChurch({ 
      name: name, 
      lngLat: [lngLat[0], lngLat[1]],
      id: id
    }))

    dispatch(setCurrentLongLat([lngLat[0], lngLat[1]]))

    setTimeout(() => {

      map.current.flyTo({
        center: lngLat,
        zoom: 11,
        essential: true,
        duration: 0
      })
    
    selected_popup.current
      .setLngLat(lngLat)
      .setHTML(`<div>${name}</div>`)
      .addTo(map.current)

    }, 1000)

  }

  function flyToChurchWithoutDetail(name: string, lngLat: LngLat) {

    map.current.flyTo({
      center: lngLat,
      zoom: 11,
      essential: true,
      duration: 0
    })

    popup.current
      .setLngLat(lngLat)
      .setHTML(`<div>${name}</div>`)
      .addTo(map.current)
    
  }

  const closePopup = () => {
    if (!popup.current) return 
    popup.current.remove()
  }

  const hoverPopup = (name: string | null, lngLat: LngLat) => {
    name ? 
      popup.current.setLngLat(lngLat).setHTML(`<div>${name}</div>`).addTo(map.current) :
      popup.current.remove()
  }


  const deaf = () => {
    if (markersSource) return
    dispatch(reset())
    setMarkersSource(true)
    navigate('/')
    removePopup()
  }
  
  const hearing = () => {
    if (!markersSource) return
    dispatch(reset())
    setMarkersSource(false)
    navigate('/')
    removePopup()
  }

  return (
    <div>
      {/* <div className={styles.sidebarStyle}>
        <div>Longitude: &emsp;{lng}</div>
        <div>Latitude:  &emsp;&emsp;&emsp;&emsp;&emsp;{lat}</div>
        <div>Zoom: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{zoom}</div>
      </div> */}
      <UI 
        flyTo={flyTo}
        flyToChurch={flyToChurch}
        flyToChurchWithoutDetail={flyToChurchWithoutDetail}
        closePopup={closePopup}
        markersWithinRange={markerWithinRange}
        isDragStart={isDragStart}
        hoverPopup={hoverPopup}
        selectedDeaf={deaf}
        selectedHearing={hearing}
        selectedMarker={markersSource}
        isMapReady={isMapReady}
        areaDeafChurches={areaDeafChurches}
        areaHearingChurches={areaHearingChurches}
        shouldDisplayArea={shouldDisplayArea}
        updateQueryRenderedChurches={updateQueryRenderedChurches}
        loading={loading}
        unclickMap={unclickMap}
        clickedMap={clickMap}
      />
      <div className={styles.mapContainer} ref={mapContainerRef} />
    </div>
  )
}

export default Map