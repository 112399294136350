import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDispatch, useSelector } from "react-redux"
import { setSearchType } from "../../../redux/reducers"
import { RootState } from "../../../redux/store"
import styles from "./index.module.css"

interface Props {
  updateQueryRenderedChurches: Function
  displayAreaClicked: Function
  handlerClickArea: Function
  unclickMap: Function
}

const SearchThisArea = (props: Props): JSX.Element => {

  const dispatch = useDispatch()
  const searchType = useSelector((state: RootState) => state.map.searchType)

  const handler_searchThisArea_onClick = () => {
    props.handlerClickArea()
    props.updateQueryRenderedChurches()
    props.displayAreaClicked()
    props.unclickMap()
    if (searchType === 1) return
    dispatch(setSearchType(false))
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} onClick={handler_searchThisArea_onClick}>
        <div className={styles.searchIconWrapper}>
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <div className={styles.text}>Search this area</div>
      </div>
    </div>
  )
}

export default SearchThisArea