import { 
  faAngleDown, 
  faAngleUp, 
  faDirections, 
  faSearch, 
  faTimesCircle 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { KeyboardEvent, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LngLat } from '../../../redux/reducers';
import { RootState } from '../../../redux/store';
import direction from '../../../utils/direction';
import distance from '../../../utils/distance';
import sendMetric from '../../../utils/sendMetric';
import { isMobile } from '../../../utils/userAgent';
import { SimpleChurch } from '../../Map';
import Suggestions from '../Suggestions'
import styles from './index.module.css'

interface Props {
  flyTo: (lngLat: LngLat) => void
  flyToChurch: (name: string, lngLat: LngLat, id: string) => void
  flyToChurchWithoutDetail: (name: string, lngLat: LngLat) => void
  closePopup: () => void
  markersWithinRange: SimpleChurch[]
  isDragStart: boolean
  hoverPopup: (enter: string | null, lngLat: LngLat) => void
  isMapReady: boolean
  deafMarkersWithinArea: any[]
  hearingMarkersWithinArea: any[]
  loading: boolean
  isAddChurchClicked: boolean
  isDisplayAreaClicked: boolean
  markerSource: boolean
  closeChurchDetail: boolean
  hideChurchform: Function
  isCloseAddChurch: boolean
  handlerOpenAddChurch: Function
  handlerCloseAddChurch: Function
  clickedArea: boolean
  clickedUserLocation: boolean
  clickedExitChurchForm: boolean
  unclickMap: Function
  clickedMap: boolean
}

enum Levels {
  a, b, c
}

interface DisplayLevel {
  level: Levels
}

const Search = (props: Props): JSX.Element => {

  const location = useLocation()
  const getPathName = location.pathname.split('/')
  const navigate = useNavigate()

  const isSelected = useSelector((state: RootState) => state.map.church_name)
  const userLocation = useSelector((state: RootState) => state.map.user_lngLat)
  const searchType = useSelector((state: RootState) => state.map.searchType)

  const getIsMobile = isMobile()

  const [enteredSearch, setEnteredSearch] = useState<string>('')
  const [found, setFound] = useState<Array<any>>([])
  const [suggestions, setSuggestions] = useState<boolean>(false)

  const inputElRef = useRef<any>(null)

  const [displayLevel, setDisplayLevel] = useState<DisplayLevel>({level: Levels.b})

  useEffect(() => {
    if (props.clickedMap) {
      setDisplayLevel({level: Levels.a})
    }
  }, [props.clickedMap])

  useEffect(() => {
    setDisplayLevel({level: Levels.b})
  }, [props.clickedArea, props.clickedUserLocation])

  useEffect(() => {
    if (getPathName.length === 2 && /\badd\b/g.test(getPathName[1])) {
      setSuggestions(false)
      props.handlerOpenAddChurch()
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]) 

  useEffect(() => {
    if (isSelected.length > 0) {
      setSuggestions(false)
    }
  }, [isSelected])

  useEffect(() => {
    if (!(displayLevel.level === Levels.c)) {
      setDisplayLevel({level: Levels.b})
    }
  }, [props.markerSource]) // eslint-disable-line react-hooks/exhaustive-deps

  // hide suggestions when drag starts
  useEffect(() => {
    if (props.isDragStart) {
      setSuggestions(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isDragStart])

  useEffect(() => {
    if (document.activeElement === inputElRef.current) {
      setSuggestions(true)
    } else {
      setSuggestions(false)
    }
  }, [suggestions])

  useEffect(() => {
    setDisplayLevel({level: Levels.b})
  }, [props.clickedExitChurchForm])

  useEffect(() => {
    if (props.isMapReady) {
      setSuggestions(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSuggestions(false)
  }, [props.markersWithinRange])

  useEffect(() => {
    if (props.isAddChurchClicked) {
        setDisplayLevel({level: Levels.a})
    }
  }, [props.isAddChurchClicked])

  useEffect(() => {
    if (props.isDisplayAreaClicked) {
      setDisplayLevel({level: Levels.b})
    }
  }, [props.isDisplayAreaClicked])

  useEffect(() => {
    if (props.closeChurchDetail) {
      props.handlerCloseAddChurch()
      setDisplayLevel({level: Levels.b})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.closeChurchDetail]) 
  
  const search_onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredSearch(e.target.value)

    await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target.value}.json?limit=5&proximity=ip&types=place%2Cpostcode%2Caddress&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`)
      .then(res => res.json())
      .then((result) => {
        if (result.features.length > 0) {
          setFound(result.features)
        }
    });
    
  }

  const search_onClick = () => {

    if (enteredSearch.length === 0) return
    
    inputElRef.current!.blur()
    props.closePopup()
    handler_lessChurchList_onClick()
    setSuggestions(false)
    props.flyTo(found[0].center)
    props.unclickMap()
    
  }
  
  const clear_onClick = () => {
    inputElRef.current!.focus()
    props.closePopup()
    setEnteredSearch('')
    setFound([])
    props.unclickMap()
  }

  const hideSuggestions = () => {
    setSuggestions(false)
  }

  const onFocusHandler = () => {
    navigate('/')
    setSuggestions(true)
    props.unclickMap()
  }
  
  function cal_miles(lngLat: LngLat): number {
    return parseFloat(distance(lngLat, userLocation).toFixed(2))
  }
  
  const handler_moreChurchList_onClick = () => {
    navigate('/')
    setDisplayLevel({level: Levels.c})
    props.unclickMap()
  }
  
  const handler_lessChurchList_onClick = () => {
    navigate('/')
    setDisplayLevel({level: Levels.b})
    props.hideChurchform()
    props.unclickMap()
  }

  const resetDisplayLevel = () => {
    setDisplayLevel({level: Levels.b})
  }

  function directions_onClick(id: string, lngLat: LngLat) {
    const url = direction(lngLat)
    window.open(url, "_self");
  }

  function mobile_html(
    id: string, 
    name: string, 
    denomination: string | null, 
    lng: number, 
    lat: number
  ): JSX.Element {
    return (
      <div key={id} className={styles.m_borderBtm}>
        <div 
          className={styles.m_infoWrapper} 
          onClick={() => {
            navigate('/')
            props.flyToChurchWithoutDetail(name, [lng, lat])
            props.hideChurchform()
            props.unclickMap()
          }}
        >
          <div className={styles.m_firstColWidth}>
            <div 
              className={`${styles.churchNameText} ${styles.m_smallerText}`} 
              onClick={() => {
                sendMetric(id, 'clicked.moreinfo').then(function () {})
                props.flyToChurch(name, [lng, lat], id)
              }}
            >
              {name}
            </div>
            <div className={`${styles.ministryTypeText} ${styles.m_smallestText}`}>{denomination}</div>
            {
              searchType === 0 &&
              <div>
                <div className={styles.m_distanceText}>{cal_miles([lng, lat])} mi <span>({(cal_miles([lng, lat])*1.609344).toFixed(2)} km)</span></div>
              </div>
            }
          </div>
          <div 
            className={styles.m_directionIconWrapper} 
            onClick={() => {
              sendMetric(id, 'clicked.opendirections').then(function () {})
              directions_onClick(id, [lng, lat])
              props.unclickMap()
            }}
          >
            <FontAwesomeIcon icon={faDirections} />
            <div>Directions</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.inputWrapper}>
        <input 
          type="text" 
          onChange={search_onChange} 
          value={enteredSearch} 
          onFocus={onFocusHandler}
          onKeyDown={function (e: KeyboardEvent<HTMLInputElement>) {
            if (e.key.toLowerCase().includes('backspace') && enteredSearch.length >= 1) {
              setFound([])
              setSuggestions(false)
            }
          }}
          onKeyUp={function (e: KeyboardEvent<HTMLInputElement>) {
            if (e.key.toLowerCase().includes('enter')) {
              search_onClick()
            }
          }}
          ref={inputElRef}
        />
        <div className={styles.searchIconWrapper} onClick={search_onClick}>
          <FontAwesomeIcon icon={faSearch}/>
        </div>
        {
          enteredSearch.length > 0 &&
          <>
            <div className={styles.vertical}></div>
            <div className={styles.searchIconWrapper} onClick={clear_onClick}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          </>
        }
      </div>
      {
        getIsMobile &&
        !suggestions && props.markersWithinRange.length > 0 &&
        <div className={styles.m_container}>
          {
            props.loading &&
            // true &&
            <div className={styles.loaderWrapper}>
              <div className={styles.loader}></div>
            </div>
          }
          {
            !(props.loading) &&
            <div className={`
              ${styles.m_churchSuggestionContainer}
              ${displayLevel.level === Levels.c ? styles.m_displayFullList_churchSuggestionContainer : ''}  
            `}>
              <div className={`
                ${styles.m_buttonsGroupContainer}
                ${displayLevel.level === Levels.c ? styles.m_fullList_buttonsGroupContainer : ''}
              `}>
                {
                  displayLevel.level === Levels.a &&
                  <div>
                    <div className={styles.m_angleIconWrapper} onClick={handler_lessChurchList_onClick}>
                      <FontAwesomeIcon icon={faAngleUp} />
                    </div>
                  </div>
                }
                {
                  displayLevel.level === Levels.b &&
                  <div>
                    <div className={styles.m_angleIconWrapper} onClick={handler_moreChurchList_onClick}>
                      <FontAwesomeIcon icon={faAngleUp} />
                    </div>
                  </div>
                }
                {
                  displayLevel.level === Levels.c &&
                  <div>
                    <div className={styles.m_angleIconWrapper} onClick={handler_lessChurchList_onClick}>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                  </div>
                }
              </div>
              {
                displayLevel.level === Levels.b &&
                <div>
                  {
                    props.markersWithinRange.map((church, i) => {
                      
                      if (i > 1) return []
                      
                      const { 
                        id,
                        name,
                        denomination,
                        longitude,
                        latitude
                      } = church

                      return mobile_html(id, name, denomination, longitude, latitude)
                    })
                  }
                </div>
              } 
              {
                displayLevel.level === Levels.c &&
                <div className={styles.m_listWrapper}>
                  {
                    props.markersWithinRange.map((church, i) => {
                      
                      const { 
                        id,
                        name,
                        denomination,
                        longitude,
                        latitude
                      } = church
        
                      return mobile_html(id, name, denomination, longitude, latitude)
                      
                    })
                  }
                </div>
              }
            </div>
          }
        </div>
      }
      { 
        !getIsMobile &&
        !suggestions && props.markersWithinRange.length > 0 &&
        <div>
          {
            props.loading &&
            // true &&
            <div className={styles.loaderWrapper}>
              <div className={styles.loader}></div>
            </div>
          }
          {
            !(props.loading) &&
            // false &&
            <div className={`
              ${styles.churchSuggestionsContainer} 
              ${props.markersWithinRange.length > 0 ? styles.scroll : ''}
            `}>
              {
                props.markersWithinRange.map((marker) => {
  
                  const { 
                    id,
                    name,
                    denomination,
                    longitude,
                    latitude
                  } = marker
  
                  return (
                    <div 
                      key={id} 
                      className={styles.rowWrapper}
                    >
                      <div 
                        className={styles.infoWrapper}
                        onMouseOver={() => props.hoverPopup(name, [longitude, latitude])}
                        onMouseLeave={() => props.hoverPopup(null, [longitude, latitude])}
                      >
                        <div>
                          <div 
                            className={styles.churchNameText} 
                            onClick={() => {
                              sendMetric(id, 'clicked.moreinfo').then(function () {})
                              props.flyToChurch(name, [longitude, latitude], id)
                            }}
                          >
                            {name}
                          </div>
                          <div className={styles.ministryTypeText}>{denomination}</div>
                        </div>
                        {
                          searchType === 0 &&
                          <div className={styles.distanceWrapper}>
                            <div>{cal_miles([longitude, latitude])} mi</div>
                            <div>({(cal_miles([longitude, latitude])*1.609344).toFixed(2)} km)</div>
                          </div>
                        }
                      </div>
                      <div 
                        className={styles.seeOnMapText}
                        onClick={() => {
                          navigate('/')
                          props.flyToChurchWithoutDetail(name, [longitude, latitude])
                        }}
                      >
                        see on map
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
      }
      {
        suggestions &&
        <div className={styles.suggestionsContainer}>
          <Suggestions 
            found={found}
            flyTo={props.flyTo}
            closePopup={props.closePopup}
            hideSuggestions={hideSuggestions}
            resetDisplayLevel={resetDisplayLevel}
          />
        </div>
      }
    </div>
  )
}

export default Search