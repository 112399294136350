import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Copy, MinistryType } from "."
import { LngLat } from "../../../redux/reducers"
import { Church } from "../../../utils/fetchChurches"
import styles from "./Info.module.css"

interface Props {
  church: Church
  live: string | null
  ministryType?: MinistryType
  copyHandler: (type: string, text: string) => void
  copy: Copy
  flyToChurchWithoutDetail: (name: string, lngLat: LngLat) => void
  closeDetail: () => void
}

const Info = (props: Props): JSX.Element => {

  function setupAddress() {

    let city = props.church.city ? props.church.city : ''
    let state = props.church.state ? props.church.state : ''

    let combined = ''

    if (city.length > 0 && state.length > 0) {
      combined = city + ', ' + state
    } else if (city.length > 0 && state.length === 0) {
      combined = city
    } else {
      combined = state
    }

    return combined
  }

  const copy_onClick = async () => {
    if (!props.church.address) return
    const text = props.church.address + ', ' + setupAddress()
    props.copyHandler('address', text)
  }

  function addressOutput(): JSX.Element {

    return (
      <div className={styles.addressWrapper}>
        <div className={styles.address}>
          {
            props.church.address &&
            <div>{props.church.address}</div>
          }
          {
            (props.church.city || props.church.state) &&
            <div>{setupAddress()}</div>
          }
        </div>
        <div className={styles.copyIconWrapper} onClick={copy_onClick}>
          <FontAwesomeIcon icon={faCopy} />
          <span className={styles.tooltipText}>{props.copy.address ? 'copied' : 'copy address'}</span>
        </div>
      </div>
    )
  }

  const handler_seeOnMap_onClick = () => {
    props.flyToChurchWithoutDetail(props.church.name, [parseFloat(props.church.longitude), parseFloat(props.church.latitude)])
    props.closeDetail()
  }

  return (
    <div className={styles.infoWrapper}>
      <div className={styles.churchNameText}>{props.church.name}</div>
      {
        (props.church.address ||
         props.church.city ||
         props.church.state) &&
        addressOutput()
      }
      {/* <div className={styles.ministryTypeText}>{props.church.ministry_type}</div> */}
      <div className={styles.extraBottom}>
        <div className={styles.typeText} style={{backgroundColor: props.ministryType?.color}}>{props.ministryType?.type}</div>
        {
          props.live &&
          <div className={styles.liveText}>
            <a href={props.live} target="_blank" rel="noreferrer">live</a>
          </div>
        }
      </div>
      <div 
        className={styles.seeOnMapText} 
        onClick={handler_seeOnMap_onClick}
      >
        see on map
      </div>
    </div>
  )
}

export default Info