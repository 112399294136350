import axios from 'axios'
import { LngLat } from '../redux/reducers'

const url = `https://api.ipdata.co?api-key=${process.env.REACT_APP_IPDATA_KEY}`

const getLocation = (): Promise<LngLat> => {
  return new Promise((resolve) => {

    getIpData().then((result) => resolve(result as LngLat))

  //   navigator.permissions.query({name:'geolocation'}).then(async (result) => {
  //     if (result.state === 'granted') {

  //       if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition((position) => {
  //           const { longitude, latitude } = position.coords;
  //           resolve([longitude, latitude]);
  //         })
  //       } else {
  //         // if unavailable service, execute this.
  //         await getIpData().then((result) => resolve(result as LngLat))
  //       }

  //     } 

  //     // if denied, execute this.
  //     await getIpData().then((result) => resolve(result as LngLat))
      
  //   });

  })
}

const getIpData = () => {
  return new Promise(async (resolve) => {
    await axios.get(url)
      .then((res) => {
        if (res.status === 200) {
          resolve([res.data.longitude, res.data.latitude])
          return
        }
        resolve([0, 0])
      })
      .catch((e) => {
        resolve([0, 0])
      })
  })
}

const userLocation = async (): Promise<LngLat> => {
  return await getLocation()
}

export default userLocation
