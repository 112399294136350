import styles from "./Picture.module.css"

interface Props {
  url: string
  name: string
}

const Picture = (props: Props): JSX.Element => {
  return (
    <div className={styles.imageWrapper}>
      <img src={props.url} alt={props.name} />
    </div>
  )
}

export default Picture