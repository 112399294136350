import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetchChurches, { Church } from '../utils/fetchChurches';
import userLocation from '../utils/userLocation';

export type LngLat = [number, number]
export enum SearchType {
  user,
  area
}

interface MapState {
  church_name: string
  id: string
  church_lngLat: LngLat
  current_lngLat: LngLat
  current_zoom: number
  user_lngLat: LngLat
  all_churches: Church[]
  searchType: SearchType
}

const defaultMap: MapState = {
  church_name: '',
  id: '',
  church_lngLat: [0,0],
  current_lngLat: [-97.380979, 42.877772],
  current_zoom: 3,
  user_lngLat: [0, 0],
  all_churches: [],
  searchType: SearchType.user
}

export const fetchAllChurches = createAsyncThunk('churches/fetchAllChurches', async () => {
  return await fetchChurches().then((data) => { return data })
})

export const fetchUserLocation = createAsyncThunk('userLocation/fetchUserLocation', async () => {
  return await userLocation().then((lngLat) => { return lngLat })
})

const mapSlice = createSlice({
  name: 'map',
  initialState: defaultMap,
  reducers: {
    selectedChurch: (state, action) => {
      state.church_name = action.payload.name
      state.id = action.payload.id
      state.church_lngLat = action.payload.lngLat
    },
    setCurrentLongLat: (state, action) => {
      state.current_lngLat = action.payload
    },
    setCurrentZoom: (state, action) => {
      state.current_zoom = action.payload
    },
    setSearchType: (state, action) => {
      action.payload ?
        state.searchType = SearchType.user :
        state.searchType = SearchType.area
    },
    reset: (state) => {
      state.church_name = ''
      state.church_lngLat = [0, 0]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllChurches.pending, () => {
        // console.log('fetching church data...')
      })
      .addCase(fetchAllChurches.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          state.all_churches = action.payload      
        } 
      })
      .addCase(fetchAllChurches.rejected, () => {
        console.log('fetching church data is not available.')
      })
    builder
      .addCase(fetchUserLocation.pending, () => {
        // console.log("fetching user location...")
      })
      .addCase(fetchUserLocation.fulfilled, (state, action) => {

        if (action.payload[0] === 0 && action.payload[1] === 0) {
          state.current_zoom = 3.5
          state.current_lngLat = [-97.380979, 42.877772]
          state.user_lngLat = [-97.380979, 42.877772]
        } else {
          state.current_zoom = 11
          state.current_lngLat = [action.payload[0], action.payload[1]]
          state.user_lngLat = [action.payload[0], action.payload[1]]
        }

      })
      .addCase(fetchUserLocation.rejected, () => {
        console.log("fetching user location is not available.")
      })
  },
});

export const { 
  selectedChurch,
  setCurrentLongLat,
  setCurrentZoom,
  setSearchType,
  reset
} = mapSlice.actions;

export default mapSlice.reducer;