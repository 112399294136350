import { 
  faCalendarAlt, 
  faSignLanguage, 
  faAmericanSignLanguageInterpreting 
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Service } from "../../../utils/fetchChurches"
import styles from "./Services.module.css"

interface Props {
  services: Service[]
}

const Services = (props: Props): JSX.Element => {

  function services() {

    let signed = [] as Service[]
    let interpreted = [] as Service[]

    for (var i = 0; i < props.services.length; i++) {
      props.services[i].ministry_type?.toLowerCase().includes('signed') ?
        signed.push(props.services[i]) :
        interpreted.push(props.services[i])
    }

    const html: JSX.Element =
      <>
        {
          signed.length > 0 &&
          <div className={styles.servicesWrapper}>
            <div className={styles.subtitleWrapper}>
              <div className={styles.subtitle}>Signed</div>
              <div className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faSignLanguage} />
              </div>
            </div>
            <div>
              {
                signed.map((service) => {
                  return (
                    serviceDetailHtml(service)
                  )
                })
              }
            </div>
          </div>
        }
        {
          interpreted.length > 0 &&
          <div className={styles.servicesWrapper}>
            <div className={styles.subtitleWrapper}>
              <div className={styles.subtitle}>Interpreted</div>
              <div className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faAmericanSignLanguageInterpreting} />
              </div>
            </div>
            <div>
              {
                interpreted.map((service) => {
                  return (
                    serviceDetailHtml(service)
                  )
                })
              }
            </div>
          </div>
        }
      </>

    return <div className={styles.wrapper}>{html}</div>
  }

  function serviceDetailHtml(obj: Service): JSX.Element {
    return (
      <div key={obj.id} className={styles.serviceDetailWrapper}>
        <div className={styles.serviceTypeText}>{obj.service_type}</div>
        {
          obj.day_of_week &&
          <>
            <div className={styles.dayOfWeekText}>{obj.day_of_week}</div>
            <div className={styles.startTimeText}>{obj.start_time ? obj.start_time : ''}</div>
            <div className={styles.note}>{obj.note ? obj.note : ''}</div>
          </>
        }
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.churchIconWrapper}>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </div>
        <div className={styles.title}>Services</div>
      </div>
      {
        services()
      }
    </div>
  )
}

export default Services